export class Controller {
    async requestAuth(user, pass) {

        const data = {
            user, pass
        };

        const req = {
            method: 'post',
            headers: {
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        };

        return await fetch('http://www.portalboehringher.mediacenterbr.com.br/sib/sistema/aplicativos/oceanraceapi/api/login.php', req);
    }
    async validateToken(token){

        const data = {
            token
        };

        const req = {
            method: 'post',
            headers: {
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        };

        return await fetch('http://www.portalboehringher.mediacenterbr.com.br/sib/sistema/aplicativos/oceanraceapi/api/validatetoken.php', req);
    }
    async getQuestionInfo(token, question_id){
        console.log(question_id)
        const data = {
            token, question_id
        };

        const req = {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        return await fetch('http://www.portalboehringher.mediacenterbr.com.br/sib/sistema/aplicativos/oceanraceapi/api/getquestioninfo.php', req);
    }

    async submitAnswer(token,id_user, id_questao, id_alternativa){
        const data = {
           token, id_user, id_questao, id_alternativa
        };

        console.log(data)

        const req = {
            method: 'post',
            headers: {
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        };

        return await fetch('http://www.portalboehringher.mediacenterbr.com.br/sib/sistema/aplicativos/oceanraceapi/api/submitanswer.php', req);
    }

    async getPersonalQuestions(token){
        const data = {
            token
        };

        const req = {
            method: 'post',
            headers: {
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        };

        return await fetch('http://www.portalboehringher.mediacenterbr.com.br/sib/sistema/aplicativos/oceanraceapi/api/getpersonalquestions.php', req);
    }

    async comparePersonalQuestions(token){
        const data = {
            token
        };

        const req = {
            method: 'post',
            headers: {
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        };

        return await fetch('http://www.portalboehringher.mediacenterbr.com.br/sib/sistema/aplicativos/oceanraceapi/api/getpersonalquestionsjson.php', req);
    }

    async activateToken(token){
        const data = {
            token
        };

        const req = {
            method: 'post',
            headers: {
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        };

        return await fetch('http://www.portalboehringher.mediacenterbr.com.br/sib/sistema/aplicativos/oceanraceapi/api/activatetoken.php', req);
    }
}

