import React from 'react';
import ReactDOM from 'react-dom';

import {Controller} from "./control/controller";
import Cookies from 'universal-cookie'

import './styles/index.css';
import App from './pages/App';

import * as serviceWorker from './serviceWorker';

export const _Cookies = new Cookies();
export const _Controller = new Controller();

ReactDOM.render(<App/>, document.getElementById('root'));

serviceWorker.unregister();


