import React from 'react'
import { Route, Redirect } from "react-router-dom";

import Home from './Home'
import Question from './Question'
import Auth from './Auth'
import {Login} from "./Login";


export class Main extends React.Component{
    componentDidMount() {
    }
    constructor(props){
        super(props)
    }
    render(){
        return (isLogged => isLogged ? (
            <div style={{width: "100%"}}>
                <Route exact path="/" component={Home}/>
                <Route
                    path="/questao"
                    render={
                        props =>
                            <Question nextQuestion={this.props.nextQuestion} prevQuestion={this.props.prevQuestion}  getQuestion={this.props.getQuestion} {...props} getStatus={this.props.getStatus} getToken={this.props.getToken}  />
                    }
                />
                <Route
                    path="/autenticacao"
                    render={
                        props =>
                            <Auth {...props}  setStatus={this.props.setStatus}  getStatus={this.props.getStatus} getToken={this.props.getToken}  />
                    }
                />
            </div>
        ) : (
            <Redirect
                to={{
                    pathname: "/login"
                }}
            />
        ))(this.props.isLogged)
    }
}

