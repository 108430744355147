import React from 'react'

import { Redirect } from "react-router-dom";

export default class Home extends React.Component{
    componentDidMount() {
    }
    render(){
        return (
            <div>
                <Redirect
                    to={{
                        pathname: "/questao"
                    }}
                />
            </div>
        )

    }
}

