import React from 'react'
import {_Controller} from '../'

import {withStyles} from "@material-ui/core";
import {blueGrey} from "@material-ui/core/colors";
import Radio from "@material-ui/core/Radio";

import he from "he"

import {Redirect} from "react-router-dom"

import '../styles/Auth.css';
import Loader from "react-loader-spinner";

import {IoIosSend} from 'react-icons/io';

const MyRadio = withStyles({
    root: {
        color: blueGrey[400],
        '&$checked': {
            color: blueGrey[600],
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

export default class Auth extends React.Component{
    componentDidMount() {
        this.state.isMounted = true;
        _Controller.getPersonalQuestions(this.props.getToken)
            .then(res => res.json())
            .then(data => {
               if(this.state.isMounted){
                   let arr = [];
                   for(let i = 0; i < data.length; i++){
                       arr.push({id_questao: data[i].id_oceanraceperguntaspessoais, id_resposta: undefined})
                   }
                   this.setState({
                       data: arr,
                       perguntas: data,
                       sending: false,
                       loading: false,
                       done: false,
                       errorMessage: "Por favor, responda à todas as questões."
                   })
               }
            })
    }
    constructor(props){
        super(props);
        this.state = {
            perguntas: undefined,
            loading: true,
            error: false,
            sending: false
        };
        this.handleOptionChange = this.handleOptionChange.bind(this);
              this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(e) {
        e.preventDefault();
        if(!this.state.data.find(u => u.id_resposta === undefined)){
            this.setState({
                error:false,
                sending: true
            });
            _Controller.comparePersonalQuestions(this.props.getToken, this.state.data.sort((a, b) =>  a.id_questao - b.id_questao))
                .then(res => res.json())
                .then(data => {
                    if(this.state.isMounted){
                        if(JSON.stringify(data) === JSON.stringify(this.state.data)){
                            _Controller.activateToken(this.props.getToken)
                                .then(res => res.json())
                                .then(data => {
                                    if(data.access === undefined){
                                        if(data.tokenActivated !== "false"){
                                            this.setState({
                                                error:false,
                                                sending: false,
                                                errorMessage: "Por favor, responda à todas as questões."
                                            }, () => {
                                                this.props.setStatus("ATIVADO")
                                            })
                                        }
                                    }
                                });
                        }else{
                            this.setState({
                                error:true,
                                sending: false,
                                errorMessage: "Falha na Autenticação"
                            })
                        }
                    }
                })
        } else {
            this.setState({
                error:true
            })
        }
    }
    handleOptionChange(i, e) {
        e.preventDefault();
        let arr = this.state.data.filter(value => value.id_questao !== i);
        arr.push({id_questao: i, id_resposta: e.target.value});
        this.setState({
            data: arr
        });
    }
    render(){
        const renderHTML = (escapedHTML) => React.createElement("p", {dangerouslySetInnerHTML: {__html: escapedHTML}});
        return  (isActivated => isActivated ?
            this.state.perguntas !== undefined ? (
                <div className="authContainer">
                    {this.state.loading === true ? (
                        <div className="loader">
                            <Loader
                                type="Rings"
                                color="#f2f2f2"
                                height={120}
                                width={120}
                            />
                        </div>
                    ) : undefined }
                    <h1>
                        <div>
                            <span style={{color:"#002F62"}}>Ocean Race</span> Quiz
                        </div>
                    </h1>
                    <h2>Questionário de Autenticação</h2>
                    {this.state.done === false && this.state.perguntas !== undefined && this.state.data !== undefined ? (
                        <form onSubmit={this.handleSubmit}>
                            <div>
                                {this.state.perguntas.map(pergunta => (
                                    <div className="boxQuestion" key={pergunta.id_oceanraceperguntaspessoais}>
                                        <h3>  {renderHTML(he.decode(pergunta.enunciado))}</h3>
                                        {pergunta.alternativas.map(alternativa => (
                                            <div key={alternativa.id_oceanracerespostasperguntaspessoais}>
                                                <label>
                                                    <MyRadio
                                                        style={{
                                                            transform:"scale(1.2",
                                                            width:30,
                                                            height:30,
                                                            marginRight:10}}
                                                        value={alternativa.id_oceanracerespostasperguntaspessoais}
                                                        onChange={(e) => this.handleOptionChange(pergunta.id_oceanraceperguntaspessoais, e)}
                                                        checked={this.state.data.find(u => u.id_resposta === alternativa.id_oceanracerespostasperguntaspessoais) !== undefined ? true : false}
                                                    />
                                                    <p>
                                                        {alternativa.alternativa}
                                                    </p>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            {this.state.error === true ? (
                                <div className="error">
                                    <div>
                                        {this.state.errorMessage}
                                    </div>
                                </div>
                            ) : undefined }
                            <button
                                style={
                                    this.state.sending === true
                                        ? {backgroundColor: "#83bc59"}
                                        : {}
                                }
                                type="submit">
                                {this.state.sending === true ? (
                                    <Loader
                                        type="ThreeDots"
                                        color="green"
                                        height={30}
                                        width={60}
                                    />
                                ) : <div>
                                    <IoIosSend></IoIosSend>
                                    Enviar
                                </div> }
                            </button>
                        </form>
                    ) : this.state.done === true ? (
                        <div>
                         Questionario encerrado.
                        </div>
                    ) : undefined}
                </div>
            ) : ""
         : (
            <Redirect
                to={{
                    pathname: "/questao"
                }}
            />
        ))(this.props.getStatus === "DESATIVADO" ? true : false)
    }
    componentWillUnmount() {
        this.state.isMounted = false;
    }
}

