import React from 'react'
import '../styles/App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import {Login} from "./Login"
import {Main} from "./Main"

import {_Cookies} from "../index";

class App extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            token: undefined,
            logged: false,
            status: "desativado",
            question: 0
        };

        this.setToken = this.setToken.bind(this);
        this.setLogged = this.setLogged.bind(this);
        this.setStatus = this.setStatus.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        this.prevQuestion = this.prevQuestion.bind(this);
        this._logoff = this._logoff.bind(this);
    }
    nextQuestion(){
        if(this.state.question < 21) {
            this.setState({
                question: this.state.question + 1
            })
        }
    }
    prevQuestion(){
        if(this.state.question > 0) {
            this.setState({
                question: this.state.question - 1
            })
        }
    }
    setLogged(bool){
        this.setState({
            logged: bool
        })
    }
    setStatus(status){
        this.setState({
            status: status
        });
    }
    setToken(token){
        this.setState({
            token: token
        });
        _Cookies.set('token', token, { path: '/' })
    }
    _logoff() {
        _Cookies.remove("token")
        this.setState({
            logged: false
        });
    }
    render() {
        return (
            <div className="container">
                <Router>
                    <Route
                        path="/login"
                        render={
                            props =>
                            <Login {...props} setStatus={this.setStatus} isLogged={this.state.logged} getToken={this.state.token} setLogged={this.setLogged} setToken={this.setToken} />
                        }
                    />
                    <Main nextQuestion={this.nextQuestion} prevQuestion={this.prevQuestion}  getQuestion={this.state.question} getToken={this.state.token} getStatus={this.state.status} setStatus={this.setStatus}  isLogged={this.state.logged}  />
                </Router>
            </div>
        )
    }
}

export default  App;
