import React from 'react'
import { Redirect } from "react-router-dom";

import Loader from 'react-loader-spinner'

import '../styles/Login.css';
import logo from '../images/logo.png';
import {_Controller} from '../'
import {_Cookies} from '../'
import he from "he";

export class Login extends React.Component{
    componentDidMount() {
        this.state.isMounted = true;
        this.validateToken()
    }
    constructor(props) {
        super(props);

        this.state = {
            email:"",
            pass:""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    validateToken() {
        const myCookie = _Cookies.get("token");
        if(myCookie  !== undefined) {
            this.setState({loading:true});
            _Controller.validateToken(_Cookies.get("token"))
              .then(u => u.json())
              .then(u => {
                if(this.state.isMounted){
                    if(u.access === "granted"){
                        this.props.setToken(_Cookies.get("token"));
                        this.props.setLogged(true);
                        this.props.setStatus(u.status);

                    } else {
                        this.props.setLogged(false)
                    }
                    this.setState({loading:false});
                }
              })
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    handleSubmit(event){
        event.preventDefault();
        this.setState({logging:true});
        _Controller.requestAuth(this.state.email,this.state.pass)
            .then(u => u.json())
            .then(u => {
              if(this.state.isMounted){
                  if(u.access === "granted") {
                      this.setState({
                          error:false
                      });
                      this.props.setToken(u.token);
                      this.validateToken();
                  } else {
                      this.setState({
                          error:true
                      });
                  }
                  return true;
              }
            })
            .then(u => {
               if(this.state.isMounted){
                   this.setState({
                       logging:false
                   });
               }
            });
    }
    render(){
        return ((isLogged) => !isLogged ? (
            <div className="loginBox">
                {this.state.logging === true || this.state.loading === true ? (
                    <div className="loader">
                        <Loader
                            type="Rings"
                            color="#83bc59"
                            height={120}
                            width={120}
                        />
                    </div>
                ) : undefined }
                <h1>
                    <div>
                        <span style={{color: "#002f62"}}>Ocean Race</span> Quiz
                    </div>
                </h1>
                <img alt="" src={logo} />
                <div>
                    <form onSubmit={this.handleSubmit}>
                        <label>
                            E-mail:
                            <input name="email" value={this.state.email} onChange={this.handleInputChange}  type="text" />
                        </label>
                        <label>
                            Senha:
                            <input name="pass" value={this.state.pass} onChange={this.handleInputChange}  type="password"  />
                        </label>
                        {this.state.error === true ? (
                            <div className="error">
                                <div>
                                    Acesso negado.
                                </div>
                            </div>
                        ) : undefined }
                        <button type="submit">
                            Entrar
                        </button>
                    </form>
                </div>
            </div>
        ) : (
            <Redirect
                to={{
                    pathname: "/"
                }}
            />
        ))(this.props.isLogged)
    }
    componentWillUnmount() {
        this.state.isMounted = false;
    }
}

